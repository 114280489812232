@use "@angular/material" as mat;
@use "src/variables.scss" as vars;

// @import '~quill/dist/quill.core.css';
// @import '~quill/dist/quill.snow.css';

@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";

// @import '@angular/material/theming';
$custom-typography: mat.m2-define-typography-config(
  $font-family: ' "Inter", "Helvetica Neue", sans-serif;',
);
// @include mat-typography-hierarchy($custom-typography);
$mat-option-selected-state-layer-color: #dae2f9;

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// @import "~@angular/material/theming";
// @import "~ng-pick-datetime/assets/style/picker.min.css";
// @import "~saturn-datepicker/bundle.css";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// @include mat-core();

// $dark-primary-text: #212121;
// $light-primary-text: #ffffff;

// $mat-blue: m3-define-palette((
//   default: #2c5bba, // Choose a default hue, e.g., 400
//   palette: (
//     50: #6f93dd,
//     100: #5a84d8,
//     200: #4575d3,
//     300: #3165ce,
//     400: #2c5bba,
//     600: #224790,
//     700: #1d3d7c,
//     800: #183367,
//     900: #142952,
//     contrast: (
//       50: $dark-primary-text,
//       100: $dark-primary-text,
//       200: $dark-primary-text,
//       300: $dark-primary-text,
//       400: $dark-primary-text,
//       600: $light-primary-text,
//       700: $light-primary-text,
//       800: $light-primary-text,
//       900: $light-primary-text,
//     ),
//   ),
// ));

// // A100: #83a3e2,
// // A200: #98b2e7,
// // A400: #adc2eb,
// // A700: #0f1e3e,

// // A100: $dark-primary-text,
// // A200: $light-primary-text,
// // A400: $light-primary-text,
// // A700: $light-primary-text,

// // Define the palettes for your theme using the Material Design palettes available in palette.scss
// // (imported above). For each palette, you can optionally specify a default, lighter, and darker
// // hue. Available color palettes: https://material.io/design/color/
// $sinapsis-admin-frontend-primary: mat.m2-define-palette($mat-blue);
// // $accent: mat.m2-define-palette(mat.$mat-blue, A200, A100, A400);
// // $warn: mat.m2-define-palette(mat.$mat-red);
// // $sinapsis-admin-frontend-accent:  mat.m2-define-palette(mat.$violet-palette);

// // The warn palette is optional (defaults to red).
// // $sinapsis-admin-frontend-warn: mat.m2-define-palette(mat.$red-palette);

// // Create the theme object (a Sass map containing all of the palettes).
// $sinapsis-admin-frontend-theme: mat.m2-define-light-theme(
//   $sinapsis-admin-frontend-primary,
//   );
//   // $sinapsis-admin-frontend-warn
//   // $sinapsis-admin-frontend-accent,

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include angular-material-theme($sinapsis-admin-frontend-theme);

@include mat.core();

// Define the theme object.
// Define the theme object.
// $sinapsis-admin-frontend-theme: mat.define-theme((
//   color: (
//     theme-type: light,
//     primary: mat.$azure-palette,
//     tertiary: mat.$violet-palette,
//   ),
//   density: (
//     scale: 0,
//   ),
//   typography: $custom-typography,
// ));

$sinapsis-admin-frontend-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: mat.$azure-palette,
      tertiary: mat.$blue-palette,
    ),
    density: (
      scale: 0,
    ),
  )
);

:root {
  --mat-select-panel-background-color: #ffffff !important;
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6) !important;
  --mdc-filled-text-field-focus-active-indicator-color: rgba(
    0,
    0,
    0,
    0.12
  ) !important;
  --mdc-switch-disabled-unselected-track-color: #44474e !important;

  @include mat.all-component-themes($sinapsis-admin-frontend-theme);
  --app-red: #cd252d;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled)
  .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.12) !important;
}

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background: #f8f8f8 !important;
}

/* Palette generated by Material Palette - materialpalette.com/light-blue/amber */

/* Import font family */
// @font-face {
//   font-family: "nunitoregular";
//   src: url("../src/assets/fonts/nunito-bold-webfont.woff") format("woff2"),
//     url("../src/assets/fonts/nunito-bold-webfont.woff") format("woff");
//   font-weight: 600;
//   font-style: normal;
// }

@font-face {
  font-family: "nunitoregular";
  src: url("../src/assets/fonts/nunito-light-webfont.woff2") format("woff2"),
    url("../src/assets/fonts/nunito-light-webfont.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "nunitoregular";
  src: url("../src/assets/fonts/nunito-regular-webfont.woff2") format("woff2"),
    url("../src/assets/fonts/nunito-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.mat-mdc-raised-button {
  background-color: vars.$primary-background-color !important;
  color: vars.$primary-text-color !important;
}

.mat-raised-cancel {
  background-color: vars.$primary-text-color !important;
  color: vars.$primary-text-color !important;
}
.app-form-field.mat-form-field-appearance-legacy .mat-form-field-underline,
.app-form-field.mat-form-field-appearance-legacy .mat-form-field-ripple,
.app-form-field.mat-form-field-appearance-legacy.mat-focused
  .mat-form-field-underline,
.app-form-field.mat-form-field-appearance-legacy.mat-focused
  .mat-form-field-ripple {
  background-color: #c4c4c4;
}

.mat-icon {
  font-family: "Material Icons" !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #2751a5;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  color: #2751a5;
  background-color: #2751a5;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: rgb(255, 37, 37, 0.26);
}
.warn-bg-color {
  background: #ffd2d2;
  color: #d8000c;
  border: 2px solid rgb(248, 190, 190);
  border-radius: none;
  height: 30px;
}

.success-bg-color {
  color: #4f8a10;
  background-color: #dff2bf;
  border: 2px solid rgb(201, 223, 164);
  border-radius: none;
  height: 30px;
}

.mat-mdc-snack-bar-container .mat-mdc-snackbar-surface {
  color: #43535c !important;
  background-color: #ffffff !important;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07) !important;
  border-radius: 6px !important;
}

.mat-snack-bar-container.multi {
  color: #43535c !important;
  background-color: #ffffff !important;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
  border-radius: 6px;
}
.mat-snack-bar-container.success {
  color: #43535c !important;
  background-color: #ffffff !important;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
  border-radius: 6px;
}

@media screen and (max-width: 1200px) {
  html,
  body {
    font-size: 12px !important;
  }
}

@media screen and (min-width: 1200px) {
  html,
  body {
    font-size: 14px !important;
  }
}

@media screen and (min-width: 1300px) {
  html,
  body {
    font-size: 15px !important;
  }
}

@media screen and (min-width: 1400px) {
  html,
  body {
    font-size: 16px !important;
  }
}

@media screen and (min-width: 1600px) {
  html,
  body {
    font-size: 16px !important;
  }
}

@media screen and (min-width: 1920px) {
  html,
  body {
    font-size: 16px !important;
  }
}

.mat-slide-toggle-bar {
  background-color: #dbdcdd;
}

.mat-slide-toggle-thumb-container {
  top: -2px !important;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #2751a5;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #fff;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: lightgray;
  border: 3px solid white;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: lightgray;
}

.xng-breadcrumb-separator {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.xng-breadcrumb-trail {
  color: vars.$primary-background-color !important;
  font-weight: bold;
}

.video-player-dialog .mat-dialog-container {
  padding: 0 !important;
}

.vjs-time-divider {
  display: block !important;
}

.video-js {
  .vjs-big-play-button {
    background-color: #1d242c !important;
  }

  .vjs-close-button {
    > .vjs-icon-placeholder::before {
      font-size: 2.4em !important;
      line-height: 1.3 !important;
    }
  }

  .vjs-control-bar {
    background-color: #1d242cb3 !important;

    .vjs-fullscreen-control {
      > .vjs-icon-placeholder::before {
        content: "" !important;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_12673_34774' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Crect width='24' height='24' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_12673_34774)'%3E%3Cpath d='M7 14V10L5 12L7 14ZM12 17L14 15H10L12 17ZM10 9H14L12 7L10 9ZM17 14L19 12L17 10V14ZM4 20C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H20C20.55 4 21.0208 4.19583 21.4125 4.5875C21.8042 4.97917 22 5.45 22 6V18C22 18.55 21.8042 19.0208 21.4125 19.4125C21.0208 19.8042 20.55 20 20 20H4ZM4 18H20V6H4V18Z' fill='white'/%3E%3C/g%3E%3C/svg%3E%0A") !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
      }
    }
  }

  &.vjs-fullscreen {
    .vjs-fullscreen-control {
      > .vjs-icon-placeholder::before {
        content: "\f109" !important;
        background-image: unset !important;
        background-repeat: unset !important;
        background-position: unset !important;
      }
    }
  }

  .vjs-volume-panel.vjs-volume-panel-horizontal {
    > .vjs-mute-control {
      > .vjs-icon-placeholder::before {
        content: "" !important;
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='18' viewBox='0 0 20 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.4298 16.9601L7.21678 13.1579L3.22687 13.3624C2.68546 13.3902 2.20751 13.217 1.79302 12.8429C1.37853 12.4688 1.1574 12.0111 1.12965 11.4697L0.925145 7.47979C0.897394 6.93839 1.07335 6.45734 1.45301 6.03666C1.83266 5.61599 2.29319 5.39177 2.8346 5.36402L6.7892 5.16132L10.5914 0.948336L11.4298 16.9601ZM9.18777 12.2426L8.86019 5.85171L7.72122 7.11375L2.9192 7.35988L3.12371 11.3498L7.92573 11.1037L9.18777 12.2426ZM12.5121 0.0887477C14.5953 0.442192 16.3318 1.39754 17.7215 2.95479C19.1111 4.51204 19.8603 6.34993 19.9689 8.46847C20.0774 10.587 19.5202 12.4919 18.297 14.183C17.0738 15.8742 15.4441 17.0021 13.408 17.5667L13.303 15.5188C14.7754 15.0067 15.939 14.1092 16.7937 12.8263C17.6484 11.5435 18.0359 10.1252 17.9562 8.57163C17.8766 7.01804 17.3461 5.64686 16.3647 4.4581C15.3834 3.26934 14.1341 2.49553 12.6171 2.13667L12.5121 0.0887477ZM12.7257 4.25521C13.6207 4.68135 14.3713 5.28011 14.9772 6.05149C15.5832 6.82287 15.9115 7.70289 15.9622 8.69154C16.0129 9.68019 15.7759 10.5833 15.2514 11.4008C14.7269 12.2183 14.0412 12.8848 13.1944 13.4002L13.0713 10.9992C13.3505 10.6899 13.5749 10.348 13.7445 9.97347C13.9141 9.59896 13.9887 9.21162 13.9681 8.81145C13.9464 8.38774 13.8348 7.99519 13.6334 7.6338C13.432 7.27241 13.1704 6.94655 12.8487 6.65622L12.7257 4.25521Z' fill='white'/%3E%3C/svg%3E%0A") !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
        background-size: 40% !important;
      }

      &.vjs-vol-0 > .vjs-icon-placeholder::before {
        content: "\f104" !important;
        background-image: unset !important;
        background-repeat: unset !important;
        background-position: unset !important;
        background-size: unset !important;
      }
    }
  }

  .vjs-time-control {
    display: block !important;
  }

  .vjs-duration,
  .vjs-current-time {
    display: block !important;
  }

  .vjs-progress-control {
    display: flex !important;
  }

  .vjs-time-control {
    font-size: 1.3em !important;
    line-height: 2.3em !important;

    &.vjs-time-divider {
      min-width: 1em !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      text-align: center !important;
    }
  }

  .vjs-play-control.vjs-control.vjs-button {
    &.vjs-playing > .vjs-icon-placeholder::before {
      font-size: 2.3em !important;
      line-height: 1.3 !important;
    }

    &.vjs-paused > .vjs-icon-placeholder::before {
      font-size: 2.5em !important;
      line-height: 1.2 !important;
    }

    &.vjs-ended > .vjs-icon-placeholder::before {
      font-size: 2em !important;
      line-height: 1.4 !important;
    }
  }
}

.couponDetail {
  .mat-dialog-container {
    padding: 24px 24px 45px 24px;
  }
}
.incentive-form {
  .mat-form-field-infix {
    display: flex !important;
    gap: 10px !important;
    border-top: 0 !important;
  }
}

.confirmationModal {
  .mat-dialog-container {
    width: 600px !important;
    height: 380px !important;
    border-radius: 10px !important;
  }
  .mat-mdc-dialog-surface {
    justify-content: center !important;
  }
}
.custom-datepicker {
  background-color: orange !important;
}

@media (min-height: 770px) {
  .resourceModal {
    height: 87vh !important;
  }

  .incentiveModal {
    height: 85vh !important;
  }
}

@media (max-height: 769px) {
  .resourceModal {
    height: 94vh !important;
  }
}

@media (max-height: 705px) {
  .resourceModal {
    height: 90vh !important;
  }

  #mainFileInputPortada {
    height: 330px !important;
  }
}

@media (max-height: 730px) {
  .incentiveModal {
    height: 85vh !important;
  }
}

@media (max-height: 720px) {
  .incentiveModal {
    height: 95vh !important;
  }
}

@media (max-height: 670px) {
  .incentiveModal {
    height: 98vh !important;
  }
}
.mat-slide-toggle-bar {
  width: 49px !important;
  height: 20px !important;
  border-radius: 40px !important;
}
.mat-slide-toggle-thumb {
  height: 26px !important;
  width: 26px !important;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  transform: translate3d(23px, 0, 0) !important;
}
.tab-specialty {
  .mat-tab-label-active {
    border: solid #2751a5 1px !important;
    width: 259px;

    opacity: 1.6 !important;
    color: #2751a5;
  }
}

/* mat-error {
  font-size: 1px !important;
} */

.custom-dialog-container-terms {
  mat-dialog-container {
    border-radius: 15px !important;
  }
}

.mat-mdc-menu-panel {
  background: #ffffff !important;
  min-width: 112px;
  max-width: 280px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  max-height: calc(100vh - 48px);
  border-radius: 4px;
  outline: 0;
  min-height: 64px;
}

.mat-elevation-z8,
.mat-raised-button {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
}

.mat-mdc-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-menu-item:hover:not([disabled]) {
  background: rgba(0, 0, 0, 0.04) !important;
}

.mat-mdc-menu-item-text {
  font-family: "nunitoregular", "Helvetica Neue", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12) !important;
  display: block;
  margin: 0;
  border-top-width: 1px;
  border-top-style: solid;
}

::ng-deep .containerterm .mdc-tab--active {
  background-color: #2751a5;
  color: #ffffff;
  opacity: 1 !important;
  font-style: normal;
  font-weight: 600;
  font-size: 0.938rem;
  line-height: 20px;
  border-radius: 10px;
}

.buttons-components {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

  background-color: #2751a5 !important;
  color: #ffffff !important;

  box-sizing: border-box !important;
  position: relative !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  cursor: pointer !important;
  outline: none !important;
  border: none !important;
  -webkit-tap-highlight-color: transparent !important;
  display: inline-block !important;
  white-space: nowrap !important;
  text-decoration: none !important;
  vertical-align: baseline !important;
  text-align: center !important;
  margin: 0 !important;
  min-width: 64px !important;
  line-height: 36px !important;
  padding: 0 16px !important;
  border-radius: 4px !important;
  overflow: visible !important;
  transform: translate3d(0, 0, 0) !important;
  transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),
    box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1) !important;
}

.all-consults {
  .mat-button-toggle-group {
    background: white;
    height: 2.5rem;
    border-radius: 5px !important;
    // border: solid 1px rgba(0, 0, 0, 0.12) !important;
  }
  .mat-button-toggle {
    display: flex !important;
    align-items: center !important;
    border: solid 1px rgba(0, 0, 0, 0.12) !important;
  }
}

.mat-datepicker-content {
  background-color: white !important;
}

.mat-calendar-body-preview-end:not(.mat-calendar-body-begin-range) {
  border-radius: 0 100% 100% 0 !important;
  background-color: #c5cae9 !important;
}

.mat-calendar-body-in-preview {
  background-color: #c5cae9 !important;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  background-color: rgb(39 81 165 / 43%) !important;
}

.mat-calendar-body-selected {
  background-color: #2751a5 !important;
  color: white !important;
}

.mat-calendar-body-in-preview .mat-calendar-body-cell-preview {
  border-top: unset !important;
  border-bottom: unset !important;
}

.mat-calendar-body-cell-content {
  border-color: transparent !important;
}
// .mat-button-toggle {
//   color: #ffffff !important;
//   background: #2751a5 !important;
// }
.mat-mdc-tooltip-surface {
  background-color: rgb(0, 0, 0) !important;
  color: white !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}

.addAdminComponent,
.addAgentComponent,
.editPatientComponent,
.addDoctorComponent,
.editDoctorComponent,
.organizationDetailComponent,
.addAllyComponent,
.addOrgInsuranceComponent,
.adminStatusComponent,
.agentStatusComponent,
.doctorStatusComponent,
.patientStatusComponent,
.organizationsListComponent {
  .mat-mdc-dialog-surface {
    border-radius: 5px !important;
    //overflow: hidden !important;
  }
}

.organizationDetailComponent {
  .mat-mdc-dialog-container {
    width: 100vw !important;
  }

  .mat-mdc-dialog-title {
    padding: 0 !important;
  }
}

.addAgentComponent {
  .mat-mdc-dialog-surface {
    overflow-y: unset !important;
  }
}

.addAdminComponent {
  .mat-mdc-dialog-surface {
    overflow-y: hidden !important;
  }
}

.mat-expansion-panel-body {
  padding: unset !important;

  .row {
    // padding: 0 24px 16px 24px !important;
  }
}

//ADD ORGNIZATION
/*.rif-field-content{
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: #f2f2f2 !important;
  }
}*/
.new-rif {
  .mat-mdc-form-field-type-mat-select:not(.mat-form-field-disabled)
    .mat-mdc-text-field-wrapper {
    background-color: red;
  }
}

.heading-edit-patient {
  padding: unset !important;
  .mat-mdc-dialog-title {
  }
}

.mat-mdc-tab-body-wrapper {
  // height: 60vh !important;
}

::-webkit-scrollbar {
  width: 5px !important;
}

::-webkit-scrollbar-thumb {
  // background: #2751a5 !important;
  border: unset !important;
}

// @media (max-height: 760px) {
//   .editDoctorComponent {
//     height: 80vh !important;
//   }
// }

@media (max-height: 700px) {
  .mat-mdc-menu-item {
    min-height: 40px !important;
  }
}

.mat-mdc-menu-item {
  min-height: 40px !important;
}
.mat-mdc-menu-item .mat-icon {
  height: auto !important;
}

.table-logs {
  .table {
    padding: 1rem !important;
  }
}

//ADD ORGANIZATION

.mdc-switch--unselected .mdc-switch__icon {
  opacity: 0 !important;
}

.mdc-switch__track {
  height: 20px !important;

  &::before {
    background: #d4d4d4 !important;
    border-width: 0px !important;
  }
}

.mat-mdc-slide-toggle .mdc-switch__handle:has(.mdc-switch__icons) {
  width: 26px !important;
  height: 26px !important;
}

////////////////////////
/// HOME
///
///
.doctor-card {
  .mat-mdc-tab {
    padding: unset !important;
    justify-content: flex-start !important;
  }
}

.mat-mdc-tab .mdc-tab-indicator__content--underline {
  border-color: #cd252d !important;
}

.mdc-list-item.mdc-list-item--with-one-line {
  height: auto !important;
}

//////TERMINOS Y CONDICIONES

.containerterm {
  .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
    color: white !important;
  }
}

/////// BUSCADOR DEL HEADER////////
::ng-deep.search-list-autocomplete {
  background-color: white !important;
}

//////////AGREGAR CUPONES///////
///
.addCouponComponent {
  .mat-mdc-dialog-surface {
    overflow: hidden !important;
  }
}

/********* LOG - REGISTRO DE ACTIVIDADES *********/
.table-logs {
  .mat-mdc-header-row,
  .mat-mdc-row {
    height: auto !important;
  }
}

/****************************/
//SELECTS
div.mat-mdc-select-panel {
  background-color: #faf9fd !important;
}

.login-form {
  .mdc-text-field,
  .mat-mdc-form-field-error-wrapper {
    padding: 0 !important;
  }
}

////////ALIADOS/////
.table-allies {
  tr.last-row {
    border-color: #dee2e6 !important;
    border-width: 1px !important;
  }
}

.mat-button-toggle-group-appearance-standard,
.mat-mdc-raised-button {
  border-radius: 5px !important;
}

.mdc-switch.mdc-switch--disabled .mdc-switch__track::before {
  background: #44474e !important;
}

.addOrgInsuranceComponent {
  // .triaje-container {
  .mat-mdc-form-field-subscript-wrapper {
    display: none !important;
  }
  // }

  .mat-mdc-tab-body-content {
    overflow: hidden !important;
  }

  .specialty-container {
    .mat-mdc-text-field-wrapper {
      height: 2rem !important;
      width: 10rem;
    }

    .mat-mdc-form-field-infix {
      height: 2rem;
      width: 10rem;
      min-height: 2rem;
      max-height: 2rem;
      padding: 3% 0%;
    }

    .mdc-checkbox__background {
      border-radius: 4px !important;
    }

    .mdc-checkbox__native-control:focus:focus:checked
      ~ .mdc-checkbox__background {
      border-color: #2751a5 !important;
      background-color: #2751a5 !important;
    }
  }

  .mat-mdc-radio-button
    .mdc-radio__native-control:checked
    + .mdc-radio__background
    .mdc-radio__inner-circle {
    transform: scale(0.7) !important;
    border-color: #2751a5 !important;
  }

  .mat-mdc-radio-button
    .mdc-radio__native-control:enabled:checked
    + .mdc-radio__background
    .mdc-radio__inner-circle {
    border-color: #2751a5 !important;
  }
}

@media (max-height: 1024px) {
  .addOrgInsuranceComponent {
    .specialty-container {
      .mat-mdc-form-field-infix {
        padding: 2% 0% !important;
      }
    }
  }
}

.resourceModal,
.incentiveModal {
  .cdk-overlay-pane.mat-mdc-dialog-panel {
    max-width: 100vw !important;
  }

  .mat-mdc-text-field-wrapper {
    border: 1px solid #c4cdd5 !important;
    border-bottom: unset !important;
    border-radius: 8px !important;
  }

  .mat-mdc-text-field-wrapper:hover {
    background-color: transparent !important;
    background: transparent !important ;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent !important;
  }

  .mat-mdc-form-field-focus-overlay {
    background-color: transparent !important;
  }
}

// .mat-datepicker-content .mat-calendar
#mat-datepicker-0 {
  width: 340px !important;
  height: max-content !important;
  box-shadow: 0 0 4px 0px #00000040 !important;
}

//AGREGAR ORGANIZACION
.addOrgInsuranceComponent {
  .mat-mdc-tab-group {
    justify-content: center !important;
    align-items: center !important;

    .mat-mdc-tab-header {
      width: 60% !important;
    }
  }

  .mat-mdc-tab-labels {
    .mat-mdc-tab {
      font-family: inherit !important;
      font-weight: 600 !important;
      font-size: 16px !important;
    }
  }

  .mat-mdc-tab,
  .mdc-tab__text-label {
    color: #0000004d !important;
  }

  .mdc-tab--active {
    border: 1px solid #2751a5 !important;
    border-radius: 1px;

    .mat-mdc-tab,
    .mdc-tab__text-label {
      color: #2751a5 !important;
    }
  }

  .mdc-tab-indicator {
    display: none !important;
  }

  .radioTriaje:nth-child(1) {
    .mdc-radio {
      padding-left: unset !important;
    }
  }
}

.organizationDetailComponent {
  // .mdc-switch__track::after {
  //   background-color: #2751A5 !important;
  //   border-color: #2751A5 !important;
  // }

  // .mdc-switch--disabled.mdc-switch .mdc-switch__track {
  //   opacity: 0.8;
  // }

  // .mdc-switch.mdc-switch--disabled.mdc-switch__track {
  // background: #2751A5 !important;
  // }

  .mdc-switch.mdc-switch--disabled .mdc-switch__track::after {
    background: #2751a5 !important;
  }

  .mdc-switch--disabled .mdc-switch__track::after {
    border-color: #2751a5 !important;
  }

  .mdc-switch--disabled.mdc-switch .mdc-switch__track {
    opacity: 0.8 !important;
  }

  .mdc-switch--disabled.mdc-switch--selected .mdc-switch__icons {
    opacity: 0.9 !important;
  }

  //SI EL CHECKED ES FALSE
  .mdc-switch.mdc-switch--disabled .mdc-switch__track::before {
    background: #e0e2ec !important;
  }
}
.mat-icon.mat-primary {
  color: #2751a5 !important;
}
